import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty } from 'vant'
import { Popup, Toast, Dialog } from 'vant'
import { Form } from 'vant'
import { Uploader } from 'vant'

Vue.use(Uploader)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Dialog)
export default {
  name: 'uplaodata',
  components: {},
  data () {
    return {
      show: false,
      bxClaimsShopName: '',
      bxClaimsOrderNo: '',
      uploadFile: {
        bxClaimsCertificateImgs: [],
        bxClaimsCaseHistoryImgs: [],
        bxClaimsBankImgs: [],
        bxClaimsCostProveImgs: [],
        bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
        bxClaimsPrescriptionDrugImgs: [],//药品处方
        bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
      },
      uploadFile1: {
        bxClaimsCertificateImgs: [],
        bxClaimsCaseHistoryImgs: [],
        bxClaimsBankImgs: [],
        bxClaimsCostProveImgs: [],
        bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
        bxClaimsPrescriptionDrugImgs: [],//药品处方
        bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
      }
    }
  },
  created () {
    if (this.$route.query.state && this.$route.query.state == 2) {
      this.claimsDetail({ id: this.$route.query.bxClaimsId }).then(res => {
        if (res.code == 200) {
          this.$store.commit('setdata', res.data)
          this.$store.commit('setFile', [])
          this.$store.commit('setFile1', [])
          this.$store.commit('setFile2', [])
          // /////////
          this.$store.commit('setFile3', [])
          this.$store.commit('setFile4', [])
          this.$store.commit('setFile5', [])
        }
      })
    }
    this.bxClaimsShopName = this.$store.state.DetailList.bxClaimsShopName
    this.bxClaimsOrderNo = this.$store.state.DetailList.bxClaimsOrderNo
    // this.uploadFile1=this.$store.state.uploadFiles;


  },
  methods: {
    ...mapActions('claims', ['uploadFlies', 'subApplyClaims', 'claimsDetail']),
    back () {
      this.$router.go(-1)
    },
    toinfoSure () {
      this.$router.push({ name: 'infoSure' })
    },
    beforeRead (file) {//判断上传图片是否超过10M
      if (file instanceof Array) {
        var isflag = true
        for (let i = 0; i < file.length; i++) {
          if (file[i].size > 10 * 1024 * 1024) {
            Toast('上传的单张图片不能超过10M')
            isflag = false
            break

          } else {
            isflag = true
          }

        }
        return isflag

      } else {
        if (file.size > 10 * 1024 * 1024) {
          Toast('上传的单张图片不能超过10M')
          return false

        } else {
          return true
        }

      }
    },
    afterRead (file, tagging) {
      var that = this
      // 此时可以自行将文件上传至服务器
      var formData1 = new FormData()
      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          formData1.append("files", file[i].file)
        }
      } else {
        formData1.append("files", file.file)
      }
      this.uploadFlies(formData1).then(res => {
        if (res.code == 200) {
          var resdata = res.data.split(',')
          for (let i = 0; i < resdata.length; i++) {
            if (tagging.name == '1') {
              that.uploadFile1.bxClaimsCertificateImgs.push(resdata[i])
            } else if (tagging.name == '2') {
              that.uploadFile1.bxClaimsCaseHistoryImgs.push(resdata[i])
            } else if (tagging.name == '7') {
              that.uploadFile1.bxClaimsBankImgs.push(resdata[i])
            } else if (tagging.name == '3') {
              that.uploadFile1.bxClaimsCostProveImgs.push(resdata[i])
            } else if (tagging.name == '4') {
              that.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs.push(resdata[i])
            } else if (tagging.name == '5') {
              that.uploadFile1.bxClaimsPrescriptionDrugImgs.push(resdata[i])
            } else if (tagging.name == '6') {
              that.uploadFile1.bxClaimsGeneticTestingReportImgs.push(resdata[i])
            }
          }
          if (tagging.name == '1') {
            this.$store.commit('setFile', this.uploadFile1.bxClaimsCertificateImgs)
          } else if (tagging.name == '2') {
            this.$store.commit('setFile1', this.uploadFile1.bxClaimsCaseHistoryImgs)
          } else if (tagging.name == '7') {
            this.$store.commit('setFile6', this.uploadFile1.bxClaimsBankImgs)
          } else if (tagging.name == '3') {
            this.$store.commit('setFile2', this.uploadFile1.bxClaimsCostProveImgs)
          } else if (tagging.name == '4') {
            this.$store.commit('setFile3', this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs)
          } else if (tagging.name == '5') {
            this.$store.commit('setFile4', this.uploadFile1.bxClaimsPrescriptionDrugImgs)
          } else if (tagging.name == '6') {
            this.$store.commit('setFile5', this.uploadFile1.bxClaimsGeneticTestingReportImgs)
          }

        } else {
          Toast(res.code)
        }
      })
    },
    deleteOne (file, detail) {
      this.uploadFile.bxClaimsCertificateImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsCertificateImgs.splice(detail.index, 1)
      this.$store.commit('setFile', this.uploadFile1.bxClaimsCertificateImgs)
    },
    //////////////
    deleteOne1 (file, detail) {
      this.uploadFile.bxClaimsCaseHistoryImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsCaseHistoryImgs.splice(detail.index, 1)
      this.$store.commit('setFile1', this.uploadFile1.bxClaimsCaseHistoryImgs)

    },
    //////////////////////////////////
    deleteOne2 (file, detail) {
      this.uploadFile.bxClaimsCostProveImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsCostProveImgs.splice(detail.index, 1)
      this.$store.commit('setFile2', this.uploadFile1.bxClaimsCostProveImgs)
    },
    //////////////
    deleteOne6 (file, detail) {
      this.uploadFile.bxClaimsBankImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsBankImgs.splice(detail.index, 1)
      this.$store.commit('setFile6', this.uploadFile1.bxClaimsBankImgs)

    },
    ////////////////////病历
    deleteOne3 (file, detail) {
      this.uploadFile.bxClaimsPathologicalDiagnosisReportImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs.splice(detail.index, 1)
      this.$store.commit('setFile3', this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs)
    },
    /////处方
    deleteOne4 (file, detail) {
      this.uploadFile.bxClaimsPrescriptionDrugImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsPrescriptionDrugImgs.splice(detail.index, 1)
      this.$store.commit('setFile4', this.uploadFile1.bxClaimsPrescriptionDrugImgs)
    },
    ///免疫
    deleteOne5 (file, detail) {
      this.uploadFile.bxClaimsGeneticTestingReportImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsGeneticTestingReportImgs.splice(detail.index, 1)
      this.$store.commit('setFile5', this.uploadFile1.bxClaimsGeneticTestingReportImgs)
    },
    /////////处方this.uploadFile1.
    temporaryStorage () {
      this.show = true
      var claimsList = this.$store.state.DetailList
      claimsList.bxClaimsCertificateImgs = this.uploadFile1.bxClaimsCertificateImgs.join(',')
      claimsList.bxClaimsCaseHistoryImgs = this.uploadFile1.bxClaimsCaseHistoryImgs.join(',')
      claimsList.bxClaimsBankImgs = this.uploadFile1.bxClaimsBankImgs.join(',')
      claimsList.bxClaimsCostProveImgs = this.uploadFile1.bxClaimsCostProveImgs.join(',')
      //  新增的三个
      claimsList.bxClaimsPathologicalDiagnosisReportImgs = this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs.join(',')
      claimsList.bxClaimsPrescriptionDrugImgs = this.uploadFile1.bxClaimsPrescriptionDrugImgs.join(',')
      claimsList.bxClaimsGeneticTestingReportImgs = this.uploadFile1.bxClaimsGeneticTestingReportImgs.join(',')
      if (claimsList.bxClaimsState == 2) {
        claimsList.beforeState = claimsList.bxClaimsState
      }
      claimsList.bxClaimsState = 1
      this.subApplyClaims(claimsList).then(res => {
        this.show = false
        if (res.code == 200) {
          this.changeData()
          this.$router.push({ name: 'prompt' })

        } else {
          Toast(res.msg)
        }

      })
    }








  },
}
